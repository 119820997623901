// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import questionnaireApi from '../../network/api/questionnaireApi';
import QuestionnaireHeader from '../../components/questionnaire/QuestionnaireHeader';
import QuestionnaireButtons from '../../components/questionnaire/QuestionnaireButtons';
import type { QuestionnaireMembreType } from '../../types/questionnaireTypes';
import QuestionnairePage from '../../components/questionnaire/QuestionnairePage';
import { notifyError, notifySuccess } from '../../network/notification';
import { findValueFromObject } from '../../utils';
import QuestionnaireAvantPropos from '../../components/questionnaire/QuestionnaireAvantPropos';
import debounce from "../../debounce";

type Props = {
  match: {
    params: {
      token: string,
    }
  },
  dispatch: Function,
}

type State = {
  selectedQuestionnaireReponse: ?QuestionnaireMembreType,
  step: number,
  nextStep: number,
  buttonFixed: boolean,
}


class Questionnaire extends React.Component<Props, State> {
  state = {
    selectedQuestionnaireReponse: null,
    step: 1,
    nextStep: 1,
    buttonFixed: false,
  };

  findInitialValues = () => {
    const generatedObject = {};
    const { selectedQuestionnaireReponse } = this.state;
    if (!selectedQuestionnaireReponse) {
      return generatedObject;
    }

    selectedQuestionnaireReponse.questionnaire.pages.forEach((page) => {
      page.questions.filter(question => question.defaultValue).forEach((question) => {
        generatedObject[`question-${question.idQuestion}`] = findValueFromObject(question.defaultValue, selectedQuestionnaireReponse.data);
      });
      page.questions
        .filter(question =>
          !generatedObject[`question-${question.idQuestion}`] &&
          question.responses && question.responses.length > 0 &&
          question.responses.some(response => response.selected))
        .forEach((question) => {
          generatedObject[`question-${question.idQuestion}`] = question.responses.find(response => response.selected).value;
        });
    });
    // On récupère les données depuis les informations enregistrées
    const { questionReponses } = selectedQuestionnaireReponse;
    questionReponses.forEach((qr) => {
      generatedObject[`question-${qr.idQuestion}`] = qr.values.length > 1 ? qr.values : qr.values[0];
    });

    return generatedObject;
  };

  convertValues = values => ({
    stepCompleted: this.state.step,
    formCompleted: this.state.selectedQuestionnaireReponse &&
      this.state.step === this.state.selectedQuestionnaireReponse.questionnaire.stepCount,
    reponses: Object
      .keys(values)
      .map(key =>
        (
          {
            idQuestion: key.replace('question-', ''),
            values: Array.isArray(values[key]) ? values[key] : [values[key]],
          }
        )),
  });

  askNextStep = (up: boolean) => {
    const nextStep = up ?
      this.state.step + 1 :
      this.state.step - 1;
    if (
      (this.state.nextStep <= this.state.step || nextStep < this.state.step)
      && this.state.selectedQuestionnaireReponse &&
      nextStep <= this.state.selectedQuestionnaireReponse.questionnaire.stepCount) {
      if (up) {
        this.setState({ nextStep });
      } else {
        this.setState({ nextStep, step: nextStep });
      }
    }
  };

  submitChanges = (values) => {
    const isFormCompleted = this.state.selectedQuestionnaireReponse &&
      this.state.step === this.state.selectedQuestionnaireReponse.questionnaire.stepCount;
    questionnaireApi.postQuestionnaire(this.props.match.params.token, this.convertValues(values))
      .then(e => (isFormCompleted ? notifySuccess(this.props.dispatch)(e) : null))
      .then(() => this.setState({ step: this.state.nextStep }))
      .catch(notifyError(this.props.dispatch));
  };
  hasToBeFixed = () => {
    const divElement = document.getElementById('questionnaire-buttons');
    if (!divElement || !divElement.parentElement) {
      return false;
    }

    return (divElement.parentElement.getBoundingClientRect().bottom > window.innerHeight);
  };

  onScroll = () => {
    if (this.hasToBeFixed() !== this.state.buttonFixed) {
      this.setState({ buttonFixed: !this.state.buttonFixed });
    }
  };

  onScrollDebounced = debounce(this.onScroll, 30);

  componentDidMount() {
    questionnaireApi
      .fetchQuestionnaire(this.props.match.params.token)
      .then(response => response.json())
      .then(response =>
        this.setState({
          selectedQuestionnaireReponse: response,
          step: response.lastStepCompleted === 0 ?
            response.lastStepCompleted : response.lastStepCompleted + 1,
          nextStep: response.lastStepCompleted === 0 ?
            response.lastStepCompleted : response.lastStepCompleted + 1,
        }))
      .catch(notifyError(this.props.dispatch));
    window.addEventListener('scroll', this.onScrollDebounced, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollDebounced);
  }

  render() {
    if (!this.state.selectedQuestionnaireReponse) {
      return null;
    }

    const { step, selectedQuestionnaireReponse, buttonFixed } = this.state;

    return (
      <main id="main-content" className="questionnaire-main-content-parent">
        <div className="questionnaire-main-content">
          <QuestionnaireHeader questionnaire={selectedQuestionnaireReponse.questionnaire} />
          <Form
            className="form-horizontal"
            onSubmit={this.submitChanges}
            initialValues={this.findInitialValues()}
            render={
              ({
                 handleSubmit, submitting, errors, values,
               }) => (
                 <form onSubmit={handleSubmit} className="questionnaire-form">
                   <div className="questionnaire-content">
                     {
                      (step > 0 &&
                        selectedQuestionnaireReponse.questionnaire.pages[step - 1]) ?
                          <QuestionnairePage
                            page={selectedQuestionnaireReponse.questionnaire.pages[step - 1]}
                            stepCount={selectedQuestionnaireReponse.questionnaire.stepCount}
                            step={step}
                            values={values}
                            errors={errors}
                            onRenderTrigger={this.onScrollDebounced}
                          /> :
                          <QuestionnaireAvantPropos
                            description={selectedQuestionnaireReponse.questionnaire.description}
                            rgpd={selectedQuestionnaireReponse.questionnaire.rgpd}
                          />
                    }
                   </div>
                   <QuestionnaireButtons
                     actualStep={step}
                     maxStep={selectedQuestionnaireReponse.questionnaire.stepCount}
                     changeStep={this.askNextStep}
                     disabled={submitting}
                     errors={errors}
                     fixed={buttonFixed}
                   />
                 </form>
              )}
          />
        </div>
      </main>
    );
  }
}

export default withRouter(connect()(Questionnaire));
