const en = {
  example: {
    modalContent: 'Je suis une modale',
  },
  actions: {
    BACK: 'Retour',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEW: 'Nouveau',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher',
  },
  message: {
    SUCCESS: 'Changes have been successfully saved',
    ERROR: 'An error append while saving changes',
    YES: 'Oui',
    NO: 'No',
  },
  wsError: {
    INTERNAL_ERROR: 'Connection error',
    AUTHENTIFICATION_EXPIRED: 'Admin session has expired',
    FIELD_REQUIRED: 'Field \'%{field0}\' is required',
    COLOR_INVALID: 'Field \'%{field0}\' must be an hexadecimal color: #ff00ff',
    EMAIL_INVALID: 'Email address \'%{field0}\' is invalid',
    USERNAME_ALREADY_EXISTS: 'This username is already used, please choose another one',
    EMAIL_ALREADY_EXISTS: 'This email address is already used, please choose another one',
    PASSWORDS_DIFFERENT: 'Passwords \'%{field0}\' et \'%{field1}\' must match',
    WRONG_LOGIN_OR_PASSWORD: 'The username or password is incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Due to login attempt errors, your account is locked for %{field0} minutes, please try again later',
    ROLE_LABEL_EXISTS: 'A role already exists with this label',
    PASSWORD_TOO_SHORT: 'The password is too short, it must be at least %{field0} characters long',
    MESSAGE: '%{field0}',
  },
  date: {
    long: 'MMMM Do, YYYY HH:mm',
    DATE_START: 'Start date',
    DATE_END: 'End date',
    DATE_FORMAT: 'MM/DD/YYYY',
  },
};

export default en;
