const fr = {
  example: {
    modalContent: 'Je suis une modale',
  },
  actions: {
    BACK: 'Retour',
    CANCEL: 'Annuler',
    SAVE: 'Enregistrer',
    DELETE: 'Supprimer',
    NEXT: 'Suivant',
    LAST: 'Précédent',
    NEW: 'Nouveau',
    START: 'Commencer',
    CONFIRM: 'Confirmer',
    SEARCH: 'Rechercher',
  },
  message: {
    SUCCESS: 'Les modifications ont bien été prises en compte',
    ERROR: 'Les modifications ont échoués',
    YES: 'Oui',
    NO: 'Non',
  },
  wsError: {
    INTERNAL_ERROR: 'Erreur de connexion',
    AUTHENTIFICATION_EXPIRED: 'Session d\'administration expirée',
    FIELD_REQUIRED: 'Le champ \'%{field0}\' est requis',
    COLOR_INVALID: 'Le champ \'%{field0}\' doit être une couleur au format hexadecimale : #ff00ff',
    EMAIL_INVALID: 'L\'adresse e-mail \'%{field0}\' n\'est pas valide',
    USERNAME_ALREADY_EXISTS: 'Cet identifiant est déjà utilisé sur un autre compte, veuillez en choisir un autre',
    EMAIL_ALREADY_EXISTS: 'Cette adresse e-mail est déjà utilisée sur un autre compte, veuillez en choisir une autre',
    PASSWORDS_DIFFERENT: 'Les mots de passe \'%{field0}\' et \'%{field1}\' doivent être identiques',
    WRONG_LOGIN_OR_PASSWORD: 'Nom d\'utilisateur ou mot de passe incorrect',
    TOO_MANY_WRONG_ATTEMPS: 'Suite à des erreurs dans la saisie de vos identifiants, votre compte est verrouillé pendant %{field0} minutes, veuillez-vous reconnecter ultérieurement',
    ROLE_LABEL_EXISTS: 'Il existe déjà un rôle avec ce libellé',
    PASSWORD_TOO_SHORT: 'Le mot de passe est trop court, il doit faire au moins %{field0} caractères',
    QUESTIONNAIRE_NOT_FOUND: 'Ce questionnaire ne semble pas exister.',
    QUESTIONNAIRE_COMPLETED: 'Ce questionnaire a déjà été complété.',
    MESSAGE: '%{field0}',
  },
  date: {
    long: 'DD MMMM YYYY HH:mm',
    DATE_START: 'Date de début',
    DATE_END: 'Date de fin',
    DATE_FORMAT: 'DD/MM/YYYY',
  },
  membre: {
    titre: {
      BUREAU: 'Bureau',
      TITULAIRE: 'Membres Titulaires',
      SUPPLEANT: 'Membres Suppléants',
      CONSEILLER: 'Conseillers',
      PERSONNEL_ADMINISTRATIF: 'Personnel Administratif',
      REPRESENTANTS: 'Représentants',
      REPRESENTANT: 'Représente la région %{instance}',
      REPRESENTANT_ONCD: 'Représente le conseil national de l\'ordre',
      FONCTION_NATIONALES: 'Fonctions nationales',
      FONCTION_REGIONALES: 'Fonctions régionales',
      FONCTION_DEPARTEMENTALES: 'Fonctions départementales',
    },
    label: {
      INSTANCE_LIE: '%{fonction} du %{instance}',
      REFERENT: 'Référent %{type}',
      COORD: 'Coordinateur %{type}',
      SUPPLEANT: 'suppléant',
    },
  },
  questionnaire: {
    stepLabel: 'Étape %{step}/%{stepCount}',
    avantPropos: 'Avant-propos',
    error: {
      required: 'Champ requis',
      wrongFormat: 'Les informations ne correspondent pas au format attendu',
      emailFormat: 'Merci de vérifier le format de l\'adresse mail',
      telephoneFormat: 'Merci de vérifier le format du numéro de téléphone. Exemple : 0144347880',
      atLeast8characters: 'Le mot de passe doit contenir au moins 8 caractères',
      complexPassword: 'Le mot de passe doit contenir : au moins une majuscule, une minuscule, un chiffre, un caractère spécial',
    },
    stepFinished: 'Vous avez terminé cette étape',
  },
};

export default fr;
