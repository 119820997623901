// @flow

import { reduce } from '../../network/reduce';

export type initialStateType = {
  +message: string,
  +isMessageDisplayed: false,
}

const initialState: initialStateType = {
  message: 'Initial reducer message',
  isMessageDisplayed: false,
};

export const MESSAGE = 'MESSAGE';
export const IS_MESSAGE_DISPLAYED = 'IS_MESSAGE_DISPLAYED';

const exampleReducer = reduce(initialState, {
  [MESSAGE]: 'message',
  [IS_MESSAGE_DISPLAYED]: 'isMessageDisplayed',
});

export default exampleReducer;
