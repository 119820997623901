// @flow

import * as React from 'react';
import { Field } from 'react-final-form';
import type { QuestionType } from '../../types/questionnaireTypes';
import { ifRequired } from '../../validator';

type Props = {
  question: QuestionType,
}

export default function RadioComponent({ question }: Props) {
  return (
    <div className="material-container-input-coreoz radios-container">
      <div className="radio-component">
        <div className="radio-label">
          {question.label} {question.required && (<span className="required">*</span>)}
        </div>
        {
          question.responses.map(reponse => (
            <Field
              id={`question-${question.idQuestion}`}
              type={question.type}
              key={`question-${question.idQuestion}${reponse.value}`}
              component="input"
              name={`question-${question.idQuestion}`}
              disabled={question.disabled}
              validate={ifRequired(question.disabled, question.required, question.customError)}
              value={reponse.value}
            >
              {({ input, meta }) => (
                <label
                  htmlFor={`question-${question.idQuestion}${reponse.value}`}
                  key={`question-${question.idQuestion}${reponse.value}`}
                  className="radio"
                >
                  <input
                    {...input}
                    disabled={question.disabled}
                    className="hidden"
                    id={`question-${question.idQuestion}${reponse.value}`}
                  />
                  <span className={`label ${question.type}`} />
                  <p>{reponse.label}</p>
                  {meta.touched && meta.error && <span className="error">{meta.error}</span>}
                </label>
              )}
            </Field>
          ))
        }
      </div>
    </div>
  );
}
