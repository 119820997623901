// @flow

import { RestClient } from '../network';

const trombinoscopeApi = {
  fetchPublic: (code: string, type: string) => new RestClient(`/trombinoscope/public?codeONCD=${code}&instanceType=${type}`, 'GET').execute(),
  fetchPrivate: (token: string) => new RestClient(`/trombinoscope/private?token=${token}`, 'GET').execute(),
};

export default trombinoscopeApi;
