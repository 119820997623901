// @flow

import { RestClient } from '../network';

const questionnaireApi = {
  fetchQuestionnaire: (token: string) => new RestClient(`/questionnaire/${token}`, 'GET').execute(),
  postQuestionnaire: (uuid: string, jsonObject: Object) => new RestClient(`/questionnaire/${uuid}`, 'POST').jsonBody(jsonObject).execute(),
};

export default questionnaireApi;
