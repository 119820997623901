// @flow

import { I18n } from 'react-redux-i18n';
import { EMAIL_REGEX, TELEPHONE_REGEX, VALIDATORS_AVAILABLE } from './const';


export const required = (value: ?string) => (value && (!Array.isArray(value) || value.length > 0) ? undefined : I18n.t('questionnaire.error.required'));
export const passwordValidator = (value: ?string) => {
  if (!value || value.length < 8) {
    return I18n.t('questionnaire.error.atLeast8characters');
  }
  const passwordFilter = [
    value.match('[a-z]'),
    value.match('[A-Z]'),
    value.match('\\d'),
    value.match('[!"#$%&\'()*+,-.\\/:;<=>?@[\\]^_`{|}~]'),
  ];

  if (passwordFilter.filter(a => a).length < 3) {
    return I18n.t('questionnaire.error.complexPassword');
  }

  return undefined;
};

export const ifRequired =
  (isDisabled: boolean, isRequired: boolean, customError: string, ...validators: any) =>
    (value: any) => ((isDisabled) ?
      undefined : overrideResult(composeValidators(isRequired ?
        required : undefined, ...validators)(value), customError));
export const emailFormat = (value: any) => (value && value.match(EMAIL_REGEX) ? undefined : I18n.t('questionnaire.error.emailFormat'));
export const telephoneFormat = (value: any) => (value && value.match(TELEPHONE_REGEX) ? undefined : I18n.t('questionnaire.error.telephoneFormat'));

export const composeValidators = (...validators: any) => (value: any) =>
  validators.filter(v => v).reduce((error, validator) => error || validator(value), undefined);
export const regexValidator = (validator: string) => (value: ?string) => (value && value.match(validator) ? undefined : I18n.t('questionnaire.error.wrongFormat'));

export const findValidators = (validator: string) => (value: any) => {
  if (!validator) {
    return undefined;
  }
  return Object.keys(VALIDATORS_AVAILABLE)
    .some(validatorName => validatorName === validator) ?
    VALIDATORS_AVAILABLE[validator](value) : regexValidator(validator)(value);
};

const overrideResult = (f: any, customError: string) => (customError && f ? customError : f);
