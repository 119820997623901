// @flow

import React from 'react';
import type { QuestionnaireType } from '../../types/questionnaireTypes';
import logoONCD from '../../img/logo_oncd.png';

type Props = {
  questionnaire: QuestionnaireType
}

export default class QuestionnaireHeader extends React.PureComponent<Props> {
  render() {
    const { questionnaire } = this.props;
    return (
      <div className="header-container">
        <img src={logoONCD} alt="logo oncd" className="ordre-logo" />
        <div className="main-header">
          <h1>{questionnaire.titre}</h1>
          <h1>{questionnaire.label}</h1>
        </div>
        {
          questionnaire.logo &&
          <img src={questionnaire.logo} alt="logo oncd" className="ordre-logo logo-questionnaire" />
        }
      </div>
    );
  }
}
