// @flow

import * as React from 'react';
import { Field } from 'react-final-form';
import { CoreozInputBase } from 'coreoz-form-base/dist';
import type { QuestionType } from '../../types/questionnaireTypes';
import { INPUT_TYPE } from '../../const';
import RadioComponent from '../form/RadioComponent';
import { findValidators, ifRequired } from '../../validator';

type Props = {
  question: QuestionType,
  errors: any,
}

export default function QuestionnaireFormInput({ question, errors }: Props) {
  return (
    <div className="questionnaire-question">
      {
        question.information &&
        <div className="questionnaire-question--information">
          <i className="fas fa-info-circle" />
          {question.information}
        </div>
      }
      {
        (question.type === INPUT_TYPE.TEXT) ?
          <Field
            type={question.type}
            component={CoreozInputBase}
            name={`question-${question.idQuestion}`}
            required={question.required}
            disabled={question.disabled}
            label={question.label}
            validate={ifRequired(
    question.disabled, question.required, question.customError,
              findValidators(question.validation),
            )}
          /> :
          <RadioComponent question={question} errors={errors} />
      }
    </div>
  );
}
