// @flow
import React from 'react';
import NotificationsSystem from 'reapop';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  HOME_PAGE_ROUTE, QUESTIONNAIRE_PAGE_ROUTE, TOKEN_PAGE_ROUTE,
} from './const';
import ConfirmationModal from './components/modaleNotification/ConfirmationModal';
import theme from './components/modaleNotification/theme/index';
import Trombinoscope from './pages/trombinoscope/Trombinoscope';
import Footer from './components/Footer';
import Questionnaire from './pages/questionnaire/Questionnaire';

type Props = {
  isAsideNavOpen: boolean,
};

function App({ isAsideNavOpen }: Props) {
  return (
    /* eslint-disable react/jsx-filename-extension */
    <div id="app">
      <NotificationsSystem theme={theme} />
      <ConfirmationModal />
      <div className="container">
        <div className="content-container">
            <Switch>
              <Route exact path={HOME_PAGE_ROUTE} component={Trombinoscope} />
              <Route exact path={TOKEN_PAGE_ROUTE} component={Trombinoscope} />
              <Route exact path={QUESTIONNAIRE_PAGE_ROUTE} component={Questionnaire} />
              <Route path="*" render={() => <Redirect to="/instance/cn/1" />} />
            </Switch>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
