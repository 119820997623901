if (!String.prototype.startsWith) {
  String.prototype.startsWith = function(searchString, position){
    position = position || 0;
    return this.substr(position, searchString.length) === searchString;
  };
}

if (!Array.prototype.find) {
  Array.prototype.find = Array.prototype.find || function(callback) {
    if (this === null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    } else if (typeof callback !== 'function') {
      throw new TypeError('callback must be a function');
    }
    var list = Object(this);
    // Makes sures is always has an positive integer as length.
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    for (var i = 0; i < length; i++) {
      var element = list[i];
      if ( callback.call(thisArg, element, i, list) ) {
        return element;
      }
    }
  };
}

if (!Array.prototype.flat) {
  Array.prototype.flat = function () {
    return (function f(arr) {
      return arr.reduce(
        (a, v) =>
          Array.isArray(v)
            ? a.concat(f(v))
            : a.concat(  v )
        , []
      )
    })(this)
  };
}

if (!String.prototype.repeat) {
  (function() {
    'use strict'; // needed to support `apply`/`call` with `undefined`/`null`
    var defineProperty = (function() {
      // IE 8 only supports `Object.defineProperty` on DOM elements
      try {
        var object = {};
        var $defineProperty = Object.defineProperty;
        var result = $defineProperty(object, object, object) && $defineProperty;
      } catch(error) {}
      return result;
    }());
    var repeat = function(count) {
      if (this == null) {
        throw TypeError();
      }
      var string = String(this);
      // `ToInteger`
      var n = count ? Number(count) : 0;
      if (n != n) { // better `isNaN`
        n = 0;
      }
      // Account for out-of-bounds indices
      if (n < 0 || n == Infinity) {
        throw RangeError();
      }
      var result = '';
      while (n) {
        if (n % 2 == 1) {
          result += string;
        }
        if (n > 1) {
          string += string;
        }
        n >>= 1;
      }
      return result;
    };
    if (defineProperty) {
      defineProperty(String.prototype, 'repeat', {
        'value': repeat,
        'configurable': true,
        'writable': true
      });
    } else {
      String.prototype.repeat = repeat;
    }
  }());
}

if (!Object.entries) {
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
      i = ownProps.length,
      resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
  };
}

if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, "includes", {
    enumerable: false,
    value: function(obj) {
      var newArr = this.filter(function(el) {
        return el == obj;
      });
      return newArr.length > 0;
    }
  });
}
