// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import type { InstanceWithPhoto, Trombinoscope as TrombinoscopeType } from '../types/trombinoscopeTypes';

type Props = {
  trombinoscopes: TrombinoscopeType[],
  instanceSelected: InstanceWithPhoto,
  selectInstance: Function,
};

type State = {
  isDropdownOpen: boolean,
}

class Header extends React.Component<Props, State> {
  state = {
    isDropdownOpen: false,
  };

  render() {
    return (
      <div className="header" id="top">
        {this.props.instanceSelected &&
        <div className="header-tab" onClick={() => this.setState({ isDropdownOpen: !this.state.isDropdownOpen })}>
          <div className="header-logo">
            <img
              src={this.props.instanceSelected.logoUrl}
              alt={this.props.instanceSelected.instance.label}
            />
          </div>
          <div>
            <i className="fal fa-caret-down" />
          </div>
        </div>
        }
        <div className={`header-select ${this.state.isDropdownOpen === true ? 'show' : ''}`}>
          {
            this.props.trombinoscopes.map(trombi => (
              <div
                key={trombi.instanceWithUrl.instance.id}
                onClick={() => {
                  this.props.selectInstance(trombi);
                  this.setState({ isDropdownOpen: false });
                }}
              >
                {trombi.instanceWithUrl.instance.label}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default Header;
