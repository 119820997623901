// @flow

import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import type { PageType, QuestionType } from '../../types/questionnaireTypes';
import QuestionnaireSteps from './QuestionnaireSteps';
import QuestionnaireFormInput from './QuestionnaireFormInput';

type Props = {
  page: PageType,
  step: number,
  stepCount: number,
  values: ?{[name: string]: string | string[]},
  errors: any,
  onRenderTrigger: Function,
}

export default function QuestionnairePage({
  page, step, stepCount, values, errors, onRenderTrigger,
}: Props) {
  onRenderTrigger();
  const linkedQuestions = page.questions.map(question =>
    question.responses
      .filter(resp => resp.linkedQuestions && resp.linkedQuestions.length > 0)
      .map(resp =>
        resp.linkedQuestions
          .map(linked =>
            ({
              question,
              value: resp.value,
              questionToShow: linked,
            }))).flat()).flat();
  const showQuestion = (question: QuestionType) =>
    question.alwaysShown || (linkedQuestions.some(linkedQuestion =>
      linkedQuestion.questionToShow === question.idQuestion &&
      values &&
      (values[`question-${linkedQuestion.question.idQuestion}`] === linkedQuestion.value ||
      (Array.isArray(values[`question-${linkedQuestion.question.idQuestion}`]) &&
        values[`question-${linkedQuestion.question.idQuestion}`].some(value => value === linkedQuestion.value))) &&
      showQuestion(linkedQuestion.question)));
  return (
    <React.Fragment>
      <QuestionnaireSteps step={step} stepCount={stepCount} />
      <div className="page-title">
        <h2>{page.label}</h2>
      </div>
      <h4>{page.descriptionPage}</h4>
      <div className="page-form-container">
        {
          page.questions
            .filter(question => showQuestion(question))
            .map(question => (
              <QuestionnaireFormInput
                key={question.idQuestion}
                question={question}
                errors={errors}
              />
          ))
        }
      </div>
      {Object.entries(errors).length === 0 &&
      <div className="step-finished"><h3><i className="fas fa-check-circle" />{I18n.t('questionnaire.stepFinished')}</h3></div>
      }
    </React.Fragment>
  );
}
