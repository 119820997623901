// @flow

import React from 'react';
import Tile from './Tile';
import type { Trombinoscope } from '../types/trombinoscopeTypes';
import { INSTANCE_TYPE, MEMBRE_TYPE } from '../const';

type Props = {
  trombinoscope: Trombinoscope,
  type: string[],
  isBureau?: boolean,
  isSuppleant?: boolean,
  isDocteur: boolean,
  selectMembre: Function,
  changeAnimationState: Function,
  className?: string,
};

export default class TileContainer extends React.PureComponent<Props> {
  static defaultProps = {
    isBureau: false,
    isSuppleant: false,
  };

  render() {
    return (
      <div className={`tile-container ${this.props.className ? this.props.className : ''}`}>
        {
          this.props.trombinoscope.membres
            .filter(membre => this.props.isBureau === membre.isBureau)
            .filter(membre => this.props.isSuppleant === membre.isSuppleant)
            .filter(membre =>
              this.props.type === MEMBRE_TYPE.ANY || this.props.type.includes(membre.fonction.code))
            .sort((membre1, membre2) => {
              if (this.props.type === MEMBRE_TYPE.ANY) {
                return 0;
              }
              if (this.props.type.indexOf(membre1.fonction.code) >
                this.props.type.indexOf(membre2.fonction.code)) {
                return 1;
              }
              if (this.props.type.indexOf(membre1.fonction.code) <
                this.props.type.indexOf(membre2.fonction.code)) {
                return -1;
              }
              return 0;
            })
            .sort((m1, m2) => {
              if (m1.fonction.code !== m2.fonction.code) {
                return 0;
              }
              if (m1.nom > m2.nom) {
                return 1;
              }
              if (m1.nom < m2.nom) {
                return -1;
              }

              if (m1.prenom > m2.prenom) {
                return 1;
              }
              if (m1.prenom < m2.prenom) {
                return -1;
              }
              return 0;
            })
            .sort((a, b) => (
              (a.ordre && b.ordre) ?
              (parseInt(a.ordre, 0) - parseInt(b.ordre, 0)) :
              0))
            .map(member =>
            (<Tile
              key={`${member.nom}${member.prenom}`}
              memberWithFunction={member}
              selectMembre={this.props.selectMembre}
              changeAnimationState={this.props.changeAnimationState}
              selected={member.selected}
              position={member.position}
              animation={member.animation}
              isDocteur={this.props.isDocteur}
              isOncd={this.props.trombinoscope.instanceWithUrl.instance.type === INSTANCE_TYPE.CN}
            />))
        }
      </div>
    );
  }
}
