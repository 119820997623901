// @flow

import * as React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  description: string,
  rgpd: string,
}

export default function QuestionnaireAvantPropos({ description, rgpd }: Props) {
  return (
    <React.Fragment>
      <div className="avant-propos-container">
        <div className="avant-propos-label">
          <h3>{I18n.t('questionnaire.avantPropos')}</h3>
        </div>
        <div className="avant-propos-text" dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className="avant-propos-container no-border" dangerouslySetInnerHTML={{ __html: rgpd }} />
    </React.Fragment>
  );
}
