// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import { INSTANCE_TYPE, phoneFormater, REFERENCE_TYPE } from '../const';

type Props = {
  memberWithFunction: any,
  selectMembre: Function,
  changeAnimationState: Function,
  selected: boolean,
  animation: boolean,
  position: string,
  isOncd: boolean,
  isDocteur: boolean,
}

export default class Tile extends React.PureComponent<Props> {
  capitalize = (string: string) =>
    string.replace(/(?:^|\s|-)\S/g, a => a.toUpperCase());

  showContact = () => (this.props.memberWithFunction.telephone ||
    this.props.memberWithFunction.mailOncd ||
    this.props.memberWithFunction.adresse);

  render() {
    return (
      <div
        className={`tile ${this.props.selected ? 'show' : ''} ${this.props.position} ${this.props.animation ? 'animated' : ''} ${this.showContact() ? 'show-contact' : ''}`}
        onTransitionEnd={() => this.props.changeAnimationState(this.props.memberWithFunction.id)}
      >
        <div className="tile-membre" id={this.props.memberWithFunction.id} >
          <div className="tile-background" />
          {this.props.memberWithFunction.selected}
          {
            this.props.memberWithFunction.photoUrl ?
              <div
                className="membre-photo"
                style={{ backgroundImage: `url("${this.props.memberWithFunction.photoUrl}")` }}
                onClick={() => {
                  this.props.selectMembre(this.props.memberWithFunction.id);
                }}
              /> :
              <div
                className="membre-photo"
                onClick={() => {
                  this.props.selectMembre(this.props.memberWithFunction.id);
                }}
              >
                <i className="fal fa-user" />
              </div>
          }
          <div
            className="membre-info"
            onClick={() => {
              this.props.selectMembre(this.props.memberWithFunction.id);
            }}
          >
            <p className="text-capitalize">
              <b>
                {`${this.props.isDocteur ? 'Dr' : ''} ${this.props.memberWithFunction.prenom.toLocaleLowerCase()} ${this.props.memberWithFunction.nom.toUpperCase()}`}
              </b>
            </p>
            <p>
              {this.props.memberWithFunction.fonction.label} {this.props.memberWithFunction.isSuppleant ? I18n.t('membre.label.SUPPLEANT') : ''}
            </p>
          </div>
          <div className="tile-info">
            { (this.props.memberWithFunction.commissions.length > 0 ||
              this.props.memberWithFunction.isRepresentantOncd) &&
              <div className="section">
                <p className="section-title">{I18n.t('membre.titre.FONCTION_NATIONALES')}</p>
                {
                  this.props.memberWithFunction.instancesLiees
                    .filter(instance => instance.typeInstance === INSTANCE_TYPE.CN).length > 0 &&
                    <p>{this.props.memberWithFunction.instancesLiees
                    .filter(instance => instance.typeInstance === INSTANCE_TYPE.CN)
                    .map(instance => `${instance.fonctionLabel} ${instance.isSuppleant ? I18n.t('membre.label.SUPPLEANT') : ''} du ${instance.label.toLocaleLowerCase()}`).join(' | ')}
                    </p>
                }
                {
                  this.props.memberWithFunction.commissions.length > 0 &&
                    this.props.memberWithFunction.commissions.map(commission =>
                      (
                        <p key={commission.label} className="low-profile">
                          <span className="text-underline">{commission.fonctionLabel}</span><br />
                          <i>{commission.label}</i>
                        </p>))
                }
              </div>
            }
            {
              (this.props.memberWithFunction.instancesLiees
                .filter(instance => instance.typeInstance === INSTANCE_TYPE.CR).length > 0 ||
                Object.keys(REFERENCE_TYPE).map(key => REFERENCE_TYPE[key])
                  .some(ref => this.props.memberWithFunction[`isCoord${ref.value}`])
              ) &&
                <div className="section">
                  <p className="section-title">{I18n.t('membre.titre.FONCTION_REGIONALES')}</p>
                  {
                    this.props.memberWithFunction.instancesLiees
                      .filter(instance => instance.typeInstance === INSTANCE_TYPE.CR).length > 0 &&
                      <p>{this.props.memberWithFunction.instancesLiees
                        .filter(instance => instance.typeInstance === INSTANCE_TYPE.CR)
                        .map(instance => `${instance.fonctionLabel} ${instance.isSuppleant ? I18n.t('membre.label.SUPPLEANT') : ''} du ${instance.label.toLocaleLowerCase()}`).join(' | ')}
                      </p>
                  }
                  {
                    Object.keys(REFERENCE_TYPE)
                      .map(key => REFERENCE_TYPE[key])
                      .filter(ref => this.props.memberWithFunction[`isCoord${ref.value}`])
                      .map(ref => (<p key={ref.label} className="low-profile">{I18n.t('membre.label.COORD', { type: ref.label })}</p>))
                  }
                </div>
            }
            {
              (this.props.memberWithFunction.instancesLiees
                .filter(instance => instance.typeInstance === INSTANCE_TYPE.CD).length > 0 ||
                Object.keys(REFERENCE_TYPE).map(key => REFERENCE_TYPE[key])
                  .some(ref => this.props.memberWithFunction[`isRef${ref.value}`])
              ) &&
                <div className="section">
                  <p className="section-title">{I18n.t('membre.titre.FONCTION_DEPARTEMENTALES')}</p>
                  <p>{
                    this.props.memberWithFunction.instancesLiees
                      .filter(instance => instance.typeInstance === INSTANCE_TYPE.CD).length > 0 &&
                    this.props.memberWithFunction.instancesLiees
                      .filter(instance => instance.typeInstance === INSTANCE_TYPE.CD)
                      .map(instance => `${instance.fonctionLabel} ${instance.isSuppleant ? I18n.t('membre.label.SUPPLEANT') : ''} du ${instance.label.toLocaleLowerCase()}`).join(' | ')}
                  </p>
                  {
                    Object.keys(REFERENCE_TYPE).map(key => REFERENCE_TYPE[key])
                      .filter(ref => this.props.memberWithFunction[`isRef${ref.value}`])
                      .map(ref => (<p className="low-profile">{I18n.t('membre.label.REFERENT', { type: ref.label })}</p>))
                  }
                </div>
            }
            {
              this.props.memberWithFunction.tropismes.length > 0 &&
              <p
                className="section"
              >
                {this.props.memberWithFunction.tropismes.map(tropisme => tropisme.label).join(' | ')}
              </p>
            }
            {
              (this.props.memberWithFunction.instancesRepresentant.length > 0) &&
              <div
                className="section"
              >
                {
                  this.props.memberWithFunction.instancesRepresentant.map(instance => (
                    <p key={instance} className="low-profile purple-text">{I18n.t('membre.titre.REPRESENTANT', { instance: this.capitalize(instance.toLocaleLowerCase()) })}</p>
                  ))
                }
              </div>
            }
          </div>
          <div className="membre-contact section border-inside">
            {
              this.props.memberWithFunction.adresse &&
              <p><i className="fal fa-home" />{this.props.memberWithFunction.adresse}</p>
            }
            {
              this.props.memberWithFunction.telephone &&
              <p>
                <i className="fal fa-phone" />
                <a href={`tel:${this.props.memberWithFunction.telephone}`} className="no-wrap">
                  {phoneFormater(this.props.memberWithFunction.telephone)}
                </a>
              </p>
            }
            {
              this.props.memberWithFunction.mailOncd &&
              <p className="membre-mail">
                <i className="fal fa-envelope" />
                <a href={`mailto:${this.props.memberWithFunction.mailOncd}`}>
                  {this.props.memberWithFunction.mailOncd.toLocaleLowerCase()}
                </a>
              </p>
            }
          </div>
        </div>
      </div>
    );
  }
}
