// @flow

import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { HashLink as Link } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';
import { MEMBRE_SECTION, MEMBRE_TYPE } from '../../const';
import type { Trombinoscope } from '../../types/trombinoscopeTypes';

type Props = {
  trombinoscope: Trombinoscope,
  forceReload: boolean,
  match: any,
  uri: string,
}

type LinkProps = {
  hash: string,
  cssStyle: string,
  linkKey: string,
  label: string,
  uri: string,
}

function AsideLink({
  hash, cssStyle, linkKey, label, uri,
}: LinkProps) {
  return (
    <Link
      smooth
      key={linkKey}
      className={`trombinoscope-link ${cssStyle} ${window.location.hash === `#${encodeURI(hash)}` ? 'selected' : ''}`}
      to={`${uri}#${hash}`}
    >
      {label}
    </Link>
  );
}

class TrombinoscopeAside extends React.PureComponent<Props> {
  render() {
    return (
      <div className="trombinoscope-aside">
        <div className="trombinoscope-menu">
          {
            this.props.trombinoscope.membres
              .find(membre => membre.isBureau &&
                !membre.isSuppleant &&
                (MEMBRE_TYPE.PRESIDENT.includes(membre.fonction.code) ||
                  MEMBRE_TYPE.BUREAU.includes(membre.fonction.code)))
            &&
            <AsideLink
              hash={MEMBRE_SECTION.BUREAU}
              linkKey={MEMBRE_SECTION.BUREAU}
              cssStyle="primary"
              label={I18n.t(`membre.titre.${MEMBRE_SECTION.BUREAU}`)}
              uri={this.props.uri}
            />
          }
          {this.props.trombinoscope.membres
            .find(membre => !membre.isBureau &&
              !membre.isSuppleant &&
              (MEMBRE_TYPE.MEMBRES.includes(membre.fonction.code)))
          &&
          <AsideLink
            linkKey={MEMBRE_SECTION.TITULAIRE}
            hash={MEMBRE_SECTION.TITULAIRE}
            cssStyle="secondary"
            label={I18n.t(`membre.titre.${MEMBRE_SECTION.TITULAIRE}`)}
            uri={this.props.uri}
          />
          }
          {this.props.trombinoscope.membres
            .find(membre =>
              membre.isSuppleant &&
              (MEMBRE_TYPE.MEMBRES.includes(membre.fonction.code)))
          &&
          <AsideLink
            linkKey={MEMBRE_SECTION.SUPPLEANT}
            hash={MEMBRE_SECTION.SUPPLEANT}
            cssStyle="tertiary"
            label={I18n.t(`membre.titre.${MEMBRE_SECTION.SUPPLEANT}`)}
            uri={this.props.uri}
          />
          }
          {this.props.trombinoscope.membres
            .find(membre =>
              membre.typeMembre === MEMBRE_SECTION.CONSEILLER)
          &&
          <AsideLink
            linkKey={MEMBRE_SECTION.CONSEILLER}
            hash={MEMBRE_SECTION.CONSEILLER}
            cssStyle="tertiary"
            label={I18n.t(`membre.titre.${MEMBRE_SECTION.CONSEILLER}`)}
            uri={this.props.uri}
          />
          }
          {this.props.trombinoscope.membres
            .find(membre =>
              membre.typeMembre === MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF)
          &&
          <React.Fragment>
            {
              this.props.trombinoscope.instanceWithUrl.services.length > 0 &&
              this.props.trombinoscope.instanceWithUrl.services
                .sort((a, b) => a.ordre - b.ordre)
                .filter(service => this.props.trombinoscope.membres.some(membre => membre.serviceId === service.id))
                .map(service => (
                  <AsideLink
                    key={service.id}
                    linkKey={service.label}
                    hash={service.label}
                    cssStyle="tertiary"
                    label={service.label}
                    uri={this.props.uri}
                  />
              ))
            }
            {
              this.props.trombinoscope.instanceWithUrl.services.length === 0 &&
              <AsideLink
                linkKey={MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF}
                hash={MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF}
                cssStyle="tertiary"
                label={I18n.t(`membre.titre.${MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF}`)}
                uri={this.props.uri}
              />
            }
          </React.Fragment>
          }
          {this.props.trombinoscope.membres
            .find(membre =>
              MEMBRE_TYPE.REPRESENTANT.includes(membre.fonction.code))
          &&
          <AsideLink
            linkKey={MEMBRE_SECTION.REPRESENTANT}
            hash={MEMBRE_SECTION.REPRESENTANT}
            cssStyle="tertiary"
            label={I18n.t(`membre.titre.${MEMBRE_SECTION.REPRESENTANT}`)}
            uri={this.props.uri}
          />
          }
        </div>
      </div>
    );
  }
}

export default withRouter(TrombinoscopeAside);
