// @flow

import React from 'react';
import trombinoscopeApi from '../../network/api/trombinoscopeApi';
import { notifyError } from '../../network/notification';
import TrombinoscopeInstance from './TrombinoscopeInstance';
import type { Trombinoscope as TrombinoscopeType } from '../../types/trombinoscopeTypes';
import Header from '../../components/Header';
import TrombinoscopeAside from './TrombinoscopeAside';
import { INSTANCE_TYPE } from '../../const';

type Props = {
  match: any,
};

type State = {
  trombinoscopeList: TrombinoscopeType[],
  trombinoscopeSelected: ?TrombinoscopeType,
  currentHash: string,
};

export default class Trombinoscope extends React.Component<Props, State> {
  compareInstance = (instance1: TrombinoscopeType, instance2: TrombinoscopeType) => {
    if (instance1.instanceWithUrl.instance.type === instance2.instanceWithUrl.instance.type) {
      if (instance1.instanceWithUrl.instance.label > instance2.instanceWithUrl.instance.label) {
        return 1;
      }
      return -1;
    }
    if (Object.keys(INSTANCE_TYPE).indexOf(instance2.instanceWithUrl.instance.type)
      < Object.keys(INSTANCE_TYPE).indexOf(instance1.instanceWithUrl.instance.type)) {
      return 1;
    }
    return -1;
  };

  state = {
    trombinoscopeList: [],
    trombinoscopeSelected: null,
    currentHash: '',
  };

  componentDidMount() {
    if (!this.props.match.params.token) {
      trombinoscopeApi
        .fetchPublic(this.props.match.params.code, this.props.match.params.type)
        .then(response => response.json())
        .then((responseJson: TrombinoscopeType[]) => {
          this.setState({
            trombinoscopeList: responseJson,
            trombinoscopeSelected: responseJson[0],
          });
        })
        .catch(notifyError);
    }

    if (this.props.match.params.token) {
      trombinoscopeApi
        .fetchPrivate(this.props.match.params.token)
        .then(response => response.json())
        .then((responseJson) => {
          responseJson.sort(this.compareInstance);
          this.setState({
            trombinoscopeList: responseJson,
            trombinoscopeSelected: responseJson[0],
          });
        })
        .catch(notifyError);
    }
  }

  selectMembre = (idMembre: string) => {
    const element = document.getElementById(idMembre);
    this.setState({
      trombinoscopeSelected: {
        ...this.state.trombinoscopeSelected,
        membres: this.state.trombinoscopeSelected.membres.map((membre) => {
          if (membre.id === idMembre) {
            membre.selected = !membre.selected;
            membre.animation = true;
            membre.position = this.isEnoughPlaceToExtend(element) ? '' : 'left';
          } else {
            if (membre.selected) {
              membre.animation = true;
            }
            membre.selected = false;
          }
          return membre;
        }),
      },
    });
  };

  changeAnimationState = (idMembre: string) => {
    this.setState({
      trombinoscopeSelected: {
        ...this.state.trombinoscopeSelected,
        membres: this.state.trombinoscopeSelected.membres.map(membre =>
          ({ ...membre, animation: membre.id === idMembre ? false : membre.animation })),
      },
    });
  };

  isEnoughPlaceToExtend = (element: HTMLElement) => (element.getBoundingClientRect().right +
      element.getBoundingClientRect().width < window.innerWidth);

  updateHash = (hash: string) => {
    window.history.pushState({}, '', `${this.props.match.token ? `/${this.props.match.token}` : window.location.pathname}#${hash}`);
    this.setState({ currentHash: hash });
  };

  render() {
    return (
      <main id="main-content">
        <div className="trombinoscope">
        <Header
          instanceSelected={this.state.trombinoscopeSelected && this.state.trombinoscopeSelected.instanceWithUrl}
          selectInstance={(trombi) => {
            this.setState({ trombinoscopeSelected: trombi });
          }}
          trombinoscopes={this.state.trombinoscopeList}
        />
        {
          this.state.trombinoscopeSelected &&
          <div className="trombinoscope-body">
            <TrombinoscopeAside
              trombinoscope={this.state.trombinoscopeSelected}
              hash={this.state.currentHash}
              uri={this.props.match.token ? `/${this.props.match.token}` : window.location.pathname}
            />
            <TrombinoscopeInstance
              key={this.state.trombinoscopeSelected.instanceWithUrl.instance.id}
              trombinoscope={this.state.trombinoscopeSelected}
              selectMembre={this.selectMembre}
              changeAnimationState={this.changeAnimationState}
              updateHash={this.updateHash}
            />
          </div>
        }
      </div>
      </main>
    );
  }
}
