// @flow

import * as React from 'react';
import { Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import type { Trombinoscope } from '../../types/trombinoscopeTypes';
import TileContainer from '../../components/TileContainer';
import { INSTANCE_TYPE, MEMBRE_SECTION, MEMBRE_TYPE, phoneFormater } from '../../const';
import debounce from "../../debounce";

type Props = {
  trombinoscope: Trombinoscope,
  selectMembre: Function,
  changeAnimationState: Function,
  updateHash: Function,
};

class TrombinoscopeInstance extends React.PureComponent<Props> {
  isInViewport = (division: HTMLElement) => {
    const rect = division.getBoundingClientRect();
    const parent = division.parentElement.getBoundingClientRect();

    return (
      ((rect.top <= parent.bottom - 50) && (rect.bottom > parent.top + 50))
    );
  };

  findNewHash = () => [
    ...Object.keys(MEMBRE_SECTION),
    ...this.props.trombinoscope.instanceWithUrl.services
      .map(service => service.label),
  ].find((element) => {
    const possibleDiv = document.getElementById(element);
    return possibleDiv ? this.isInViewport(possibleDiv) : false;
  });

  autoSelectCategory = () => {
    const selectedDocument = document.getElementById(decodeURI(window.location.hash.replace('#', '')));
    if (!selectedDocument || !this.isInViewport(selectedDocument)) {
      const newHash = this.findNewHash();
      if (newHash) {
        this.props.updateHash(newHash);
      }
    }
  };

  autoSelectCategoryDebounced = debounce(this.autoSelectCategory, 30);

  render() {
    return (
      <div className="trombinoscope-instance" id={this.props.trombinoscope.instanceWithUrl.instance.id} onScroll={this.autoSelectCategoryDebounced}>
        <div className="instance-header">
          <div className="instance-name"><h1>{this.props.trombinoscope.instanceWithUrl.instance.label}</h1></div>
          <div className="instance-details">
            {
              this.props.trombinoscope.instanceWithUrl.instance.adresse &&
              <p>
                <i className="fal fa-home" />
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURI(this.props.trombinoscope.instanceWithUrl.instance.adresse)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {this.props.trombinoscope.instanceWithUrl.instance.adresse}
                </a>
              </p>
            }
            {
              this.props.trombinoscope.instanceWithUrl.instance.telephone &&
              <p>
                <i className="fal fa-phone" />
                <a href={`tel:${this.props.trombinoscope.instanceWithUrl.instance.telephone}`}>
                  {phoneFormater(this.props.trombinoscope.instanceWithUrl.instance.telephone)}
                </a>
              </p>
            }
            {
              this.props.trombinoscope.instanceWithUrl.instance.email &&
              <p>
                <i className="fal fa-paper-plane" />
                <a href={`mailto:${this.props.trombinoscope.instanceWithUrl.instance.email}`}>
                  {this.props.trombinoscope.instanceWithUrl.instance.email}
                </a>
              </p>
            }
          </div>
        </div>
        <div className="trombinoscope-content">
          {
            this.props.trombinoscope.membres
              .find(membre => membre.isBureau &&
                !membre.isSuppleant &&
                (MEMBRE_TYPE.BUREAU.includes(membre.fonction.code)))
            &&
            <div className="trombinoscope-section" id={MEMBRE_SECTION.BUREAU}>
              <div>
                <h2><Translate value={`membre.titre.${MEMBRE_SECTION.BUREAU}`} /></h2>
              </div>
              <TileContainer
                type={MEMBRE_TYPE.PRESIDENT}
                trombinoscope={this.props.trombinoscope}
                selectMembre={this.props.selectMembre}
                changeAnimationState={this.props.changeAnimationState}
                isBureau
                isDocteur
                className="centered-tile"
              />
              <TileContainer
                type={MEMBRE_TYPE.VICE_PRESIDENT}
                trombinoscope={this.props.trombinoscope}
                selectMembre={this.props.selectMembre}
                changeAnimationState={this.props.changeAnimationState}
                isBureau
                isDocteur
              />
              {
                this.props.trombinoscope.instanceWithUrl.instance.type === INSTANCE_TYPE.CD &&
                <TileContainer
                  type={MEMBRE_TYPE.SECRETAIRE_GENERAL_TRESORIER_CD}
                  trombinoscope={this.props.trombinoscope}
                  selectMembre={this.props.selectMembre}
                  changeAnimationState={this.props.changeAnimationState}
                  isBureau
                  isDocteur
                />
              }
              {
                (
                  this.props.trombinoscope.instanceWithUrl.instance.type === INSTANCE_TYPE.CR ||
                  this.props.trombinoscope.instanceWithUrl.instance.type === INSTANCE_TYPE.CN
                ) &&
                <React.Fragment>
                  <TileContainer
                    type={MEMBRE_TYPE.SECRETAIRE_GENERAL}
                    trombinoscope={this.props.trombinoscope}
                    selectMembre={this.props.selectMembre}
                    changeAnimationState={this.props.changeAnimationState}
                    isBureau
                    isDocteur
                  />
                  {
                    this.props.trombinoscope.instanceWithUrl.instance.type === INSTANCE_TYPE.CN ?
                      (<TileContainer
                        type={MEMBRE_TYPE.TRESORIER_CN}
                        trombinoscope={this.props.trombinoscope}
                        selectMembre={this.props.selectMembre}
                        changeAnimationState={this.props.changeAnimationState}
                        isBureau
                        isDocteur
                      />) : (
                        <React.Fragment>
                          <TileContainer
                            type={MEMBRE_TYPE.TRESORIER}
                            trombinoscope={this.props.trombinoscope}
                            selectMembre={this.props.selectMembre}
                            changeAnimationState={this.props.changeAnimationState}
                            isBureau
                            isDocteur
                          />
                          <TileContainer
                            type={MEMBRE_TYPE.TRESORIER_ADJOINT}
                            trombinoscope={this.props.trombinoscope}
                            selectMembre={this.props.selectMembre}
                            changeAnimationState={this.props.changeAnimationState}
                            isBureau
                            isDocteur
                          />
                        </React.Fragment>
                      )
                  }
                </React.Fragment>
              }
            </div>
          }
          {this.props.trombinoscope.membres
            .find(membre => !membre.isBureau &&
              !membre.isSuppleant &&
              (MEMBRE_TYPE.MEMBRES.includes(membre.fonction.code)))
          &&
          <div className="trombinoscope-section" id={MEMBRE_SECTION.TITULAIRE}>
            <div>
              <h2><Translate value={`membre.titre.${MEMBRE_SECTION.TITULAIRE}`} /></h2>
            </div>
            <TileContainer
              type={MEMBRE_TYPE.MEMBRES}
              trombinoscope={this.props.trombinoscope}
              selectMembre={this.props.selectMembre}
              changeAnimationState={this.props.changeAnimationState}
              isDocteur
            />
          </div>
          }
          {this.props.trombinoscope.membres
            .find(membre =>
              membre.isSuppleant &&
              (MEMBRE_TYPE.MEMBRES.includes(membre.fonction.code)))
          &&
          <div className="trombinoscope-section" id={MEMBRE_SECTION.SUPPLEANT}>
            <div>
              <h2><Translate value={`membre.titre.${MEMBRE_SECTION.SUPPLEANT}`} /></h2>
            </div>
            <TileContainer
              type={MEMBRE_TYPE.MEMBRES}
              trombinoscope={this.props.trombinoscope}
              selectMembre={this.props.selectMembre}
              changeAnimationState={this.props.changeAnimationState}
              isSuppleant
              isDocteur
            />
            <TileContainer
              type={MEMBRE_TYPE.BUREAU}
              trombinoscope={this.props.trombinoscope}
              selectMembre={this.props.selectMembre}
              changeAnimationState={this.props.changeAnimationState}
              isSuppleant
              isDocteur
            />
          </div>
          }
          {this.props.trombinoscope.membres
            .find(membre =>
              membre.typeMembre === MEMBRE_SECTION.CONSEILLER) &&
              <div className="trombinoscope-section" id={MEMBRE_SECTION.CONSEILLER}>
                <div>
                  <h2><Translate value={`membre.titre.${MEMBRE_SECTION.CONSEILLER}`} /></h2>
                </div>
                <TileContainer
                  type={MEMBRE_TYPE.ANY}
                  trombinoscope={{
                    ...this.props.trombinoscope,
                    membres:
                      this.props.trombinoscope.membres.filter(membre => membre.typeMembre === MEMBRE_SECTION.CONSEILLER),
                  }}
                  selectMembre={this.props.selectMembre}
                  changeAnimationState={this.props.changeAnimationState}
                  isDocteur={false}
                />
              </div>
          }
          {this.props.trombinoscope.membres
            .find(membre =>
              membre.typeMembre === MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF) &&
              <React.Fragment>
                {
                this.props.trombinoscope.instanceWithUrl.services.length > 0
                && this.props.trombinoscope.instanceWithUrl.services
                  .filter(service => this.props.trombinoscope.membres
                    .some(membre => membre.serviceId === service.id))
                  .map(service => (
                    <div className="trombinoscope-section" id={service.label} key={service.id}>
                      <div>
                        <h2><Translate value={`membre.titre.${service.label}`} /></h2>
                      </div>
                      <TileContainer
                        type={MEMBRE_TYPE.ANY}
                        trombinoscope={{
                       ...this.props.trombinoscope,
                      membres:
                        this.props.trombinoscope.membres
                          .filter(membre =>
                            membre.typeMembre === MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF && membre.serviceId === service.id),
                      }}
                        selectMembre={this.props.selectMembre}
                        changeAnimationState={this.props.changeAnimationState}
                        isDocteur={false}
                      />
                    </div>
                  ))
              }
                {
                  this.props.trombinoscope.instanceWithUrl.services.length === 0 &&
                  <div className="trombinoscope-section" id={MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF}>
                    <div>
                      <h2><Translate value={`membre.titre.${MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF}`} /></h2>
                    </div>
                    <TileContainer
                      type={MEMBRE_TYPE.ANY}
                      trombinoscope={{
                        ...this.props.trombinoscope,
                        membres: this.props.trombinoscope.membres
                          .filter(membre => membre.typeMembre === MEMBRE_SECTION.PERSONNEL_ADMINISTRATIF)
                          .sort((a, b) => parseInt(a.ordre) - parseInt(b.ordre)),
                      }}
                      selectMembre={this.props.selectMembre}
                      changeAnimationState={this.props.changeAnimationState}
                      isDocteur={false}
                    />
                  </div>
                }
              </React.Fragment>
          }
          {
            this.props.trombinoscope.membres
              .find(membre => MEMBRE_TYPE.REPRESENTANT.includes(membre.fonction.code)) &&
              <div className="trombinoscope-section" id={MEMBRE_SECTION.REPRESENTANT}>
                <div>
                  <h2><Translate value={`membre.titre.${MEMBRE_SECTION.REPRESENTANT}`} /></h2>
                </div>
                <TileContainer
                  type={MEMBRE_TYPE.ANY}
                  trombinoscope={{
                  ...this.props.trombinoscope,
                  membres: this.props.trombinoscope.membres
                    .filter(membre => MEMBRE_TYPE.REPRESENTANT.includes(membre.fonction.code) && membre.isRepresentantOncd),
                }}
                  selectMembre={this.props.selectMembre}
                  changeAnimationState={this.props.changeAnimationState}
                  isDocteur
                />
              </div>

          }
        </div>
      </div>
    );
  }
}

export default withRouter(TrombinoscopeInstance);
