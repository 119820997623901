// @flow

import React from 'react';

type Props = {};

export default class Footer extends React.PureComponent<Props> {
  render() {
    return (
      <footer className="footer">
        <div className="first-level">
          <div className="panel">
            <p>Ordre national des chirurgiens-dentistes</p>
            <p><a href={`https://www.google.com/maps/search/?api=1&query=${encodeURI('22 rue Emile Ménier')}`} target="_blank" rel="noopener noreferrer">22 rue Emile Ménier</a></p>
            <p>75761 Paris cedex 16</p>
          </div>
          <div className="panel">
            <p><a href="tel:+33144347880"><i className="fal fa-phone" /> 01 44 34 78 80</a></p>
            <p><a href="mailto:courrier@oncd.org"><i className="fal fa-envelope" /> courrier@oncd.org</a></p>
          </div>
        </div>
        <hr />
        <div className="second-level">© Copyright 2016 - 2019 ONCD.</div>
      </footer>
    );
  }
}
