// @flow

import { emailFormat, passwordValidator, telephoneFormat } from './validator';

export const baseApiUrl = '/api';

// Routes
export const HOME_PAGE_ROUTE = '/instance/:type/:code';
export const TOKEN_PAGE_ROUTE = '/:token';
export const QUESTIONNAIRE_PAGE_ROUTE = '/questionnaire/:token';

export const NOTIFY_CONFIRMATION = {
  message: 'Les modifications ont bien été prises en compte',
  status: 'success',
  dismissible: true,
  dismissAfter: 3000,
};

export const getQueryParams = () => {
  const validQueryParams = window.location.search.substring(1)
    .split('&')
    .filter(param => /^\w+[=]\w+$/g.test(param))
    .join();
  return validQueryParams ?
    JSON.parse(`{"${
      decodeURI(validQueryParams)
        .replace(/"/g, '\\"')
        .replace(/,/g, '","')
        .replace(/=/g, '":"')}"}`) :
    {};
};

export const MEMBRE_TYPE = {
  PRESIDENT: ['PR'],
  VICE_PRESIDENT: ['VP'],
  SECRETAIRE_GENERAL: ['SG'],
  TRESORIER: ['TR'],
  TRESORIER_ADJOINT: ['TA'],
  TRESORIER_CN: ['TR', 'TA'],
  SECRETAIRE_GENERAL_TRESORIER_CD: ['SG', 'TR'],
  MEMBRES: ['MB'],
  BUREAU: ['TR', 'SG', 'VP', 'PR', 'TA'],
  REPRESENTANT: ['REP'],
  ANY: ['ANY'],
};

export const MEMBRE_SECTION = {
  BUREAU: 'BUREAU',
  TITULAIRE: 'TITULAIRE',
  SUPPLEANT: 'SUPPLEANT',
  CONSEILLER: 'CONSEILLER',
  PERSONNEL_ADMINISTRATIF: 'PERSONNEL_ADMINISTRATIF',
  REPRESENTANT: 'REPRESENTANTS',
};

export const REFERENCE_TYPE = {
  DHPR:
    {
      label: 'DHPR',
      value: 'Dhpr',
    },
  VIOLENCE:
    {
      label: 'Violence',
      value: 'Violence',
    },
  NUMERIQUE: {
    label: 'Numérique',
    value: 'Numerique',
  },
};

export const INSTANCE_TYPE = { CN: 'CN', CR: 'CR', CD: 'CD' };

export const phoneFormater = (phone: string) => {
  if (phone.startsWith('0')) {
    return phone.toLocaleLowerCase().trim().match(/[0-9][0-9]/g).join(' ');
  }
  return '';
};

export const INPUT_TYPE = {
  TEXT: 'text',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
};

export const EMAIL_REGEX = '^[^@]+@[^@]+\\.[a-zA-Z]+$';
export const TELEPHONE_REGEX = '^\\d{10}$';

export const VALIDATORS_AVAILABLE = {
  email: emailFormat,
  phone: telephoneFormat,
  password: passwordValidator,
};
