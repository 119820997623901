// @flow

import React from 'react';
import { I18n } from 'react-redux-i18n';
import debounce from '../../debounce';

type Props = {
  step: number,
  stepCount: number
}

type State = {
  fixed: boolean,
}

export default class QuestionnaireSteps extends React.Component<Props, State> {
  state = {
    fixed: false,
  };

  hasToBeFixed = () => {
    const divElement = document.getElementById('questionnaire-step');
    if (!divElement || !divElement.parentElement) {
      return false;
    }
    return (divElement.parentElement.getBoundingClientRect().top < 0
      && divElement.getBoundingClientRect().top <= 6);
  };

  onScroll = () => {
    if (this.hasToBeFixed() !== this.state.fixed) {
      this.setState({ fixed: !this.state.fixed });
    }
  };

  onScrollDebounced = debounce(this.onScroll, 20);

  componentDidMount() {
    window.addEventListener('scroll', this.onScrollDebounced, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollDebounced);
  }

  render() {
    const { step, stepCount } = this.props;
    return (
      <div
        id="questionnaire-step"
        className={`step-shower ${this.state.fixed ? 'fixed' : ''}`}
      >
        {I18n.t('questionnaire.stepLabel', { step, stepCount })}
        <div className="progress-bar">
          <div className="progress-bar-value" style={{ width: `${100 * (step / stepCount)}%` }} />
        </div>
      </div>
    );
  }
}
