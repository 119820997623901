// @flow

import * as React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  actualStep: number,
  maxStep: number,
  changeStep: Function,
  disabled: ?boolean,
  errors: any,
  fixed: boolean,
}

export default function QuestionnaireButtons({
  actualStep, maxStep, changeStep, disabled, errors, fixed,
}: Props) {
  return (
    <div id="questionnaire-buttons" className={`questionnaire-buttons ${fixed ? 'fixed' : ''}`}>
      {actualStep > 1 &&
        <button
          type="button"
          className="btn btn-reversed"
          onClick={() => changeStep(false)}
        >
          {I18n.t('actions.LAST')}
        </button>
      }

      <button
        className="btn"
        type="submit"
        onClick={() => changeStep(true)}
        disabled={disabled}
      >
        {actualStep === 0 &&
          I18n.t('actions.START')
        }
        {
          actualStep > 0 && actualStep < maxStep &&
            I18n.t('actions.NEXT')
        }
        {
          actualStep === maxStep &&
            I18n.t('actions.SAVE')
        }
      </button>
    </div>
  );
}
